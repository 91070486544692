import React, { useEffect } from 'react'
import 'twin.macro'

import SEO from 'components/shared/seo'
import useVisitNewYork from 'content-queries/mx/visit-new-york'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import { TextDivider } from 'mx/components/text-divider'
import { Press } from 'mx/homepage/press'
import { Reviews } from 'mx/homepage/reviews'
import Layout from 'mx/layout'
import { BottomCta } from 'mx/locations/bottom-cta'
import { SectionContent } from 'mx/locations/section-content'
import { SectionContentFull } from 'mx/locations/section-content-full'

import { Header } from '../mx/components/new'

const VisitNewYorkPage = () => {
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Visit New York Studio',
    })
  }, [])

  const { data } = useVisitNewYork()
  const [
    {
      node: {
        sectionModules: [
          hero,
          perform,
          designed,
          engineered,
          inspiration,
          price,
          map,
          bottomCta,
        ],
      },
    },
  ] = data

  return (
    <Layout header={false}>
      <AutomaticPopup />
      <Header data={hero} addTransparency />
      <Reviews />
      <TextDivider title="As seen in" />
      <Press hideQuotes={true} />
      <SectionContent data={perform} />
      <SectionContent data={designed} />
      <SectionContent data={engineered} />
      <SectionContent data={inspiration} />
      <SectionContentFull data={price} />
      <SectionContent data={map} />
      <BottomCta data={bottomCta} />
    </Layout>
  )
}
export default VisitNewYorkPage

export const Head = () => {
  const { data } = useVisitNewYork()
  const [
    {
      node: { image },
    },
  ] = data

  return (
    <SEO
      description="Feel the quality of our European cabinetry and speak with our expert consultants at our New York Studio."
      path="/visit-new-york"
      title="New York Studio"
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
    />
  )
}
